// Common Loader of the system
import React, { useState, useEffect } from "react";
import "./layout.css";
import { Spin } from "antd";

export default function Loader(props) {
  const { size, isFromMerchantCreation = false } = props; // Default isFromMerchantCreation to false

  // Messages for the "isFromMerchantCreation" flow
  const merchantMessages = [
    "Creating your merchant",
    "Initializing your webshop",
    "Deploying your webshop",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [dots, setDots] = useState("");

  useEffect(() => {
    if (isFromMerchantCreation) {
      // Cycle through dots for animation
      const dotsInterval = setInterval(() => {
        setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
      }, 500);

      // Cycle through messages
      const messageInterval = setInterval(() => {
        setCurrentMessageIndex(
          (prevIndex) => (prevIndex + 1) % merchantMessages.length
        );
      }, 5000); // Update message every 5 seconds

      return () => {
        clearInterval(dotsInterval);
        clearInterval(messageInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFromMerchantCreation]);

  const currentTip = isFromMerchantCreation
    ? `${merchantMessages[currentMessageIndex]}${dots}`
    : "Loading...";

  return (
    <div className="loader">
      <Spin tip={currentTip} size={size ? size : "large"} />
    </div>
  );
}
